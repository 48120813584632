import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { ImagePass } from '../../Image/ImagePass';
import Arrow from '../../Svg/Arrow';
import './PostListItem.scss'
import { decodeEntities } from '../../../utils/helpers';

export default class ListItem extends React.Component {
  render () {
    const { siteMetadata, data, pathPrefix, showAuthor, client } = this.props;
    const { excerpt, title, slug, featured_image_url: image, author, date, categories = [] } = data;
    return (
      <div className="post-list-item">
        <div>
          <div className="image"><GatsbyLink to={`/${pathPrefix}/${slug}/`} label={title}><ImagePass src={image} className="background" /></GatsbyLink></div>
          <div className="inner">
            {(categories && categories.length > 0) && <div className="post-list-item-category"><span>{decodeEntities(categories[0].name)}</span></div>}
            <h4 className="title"><GatsbyLink to={`/${pathPrefix}/${slug}/`}>{client ? `${decodeEntities(client)} - ` : ''}{decodeEntities(title)}</GatsbyLink></h4>
            {excerpt && <RenderContent content={`${excerpt}`}/>}
            {slug && <GatsbyLink to={`/${pathPrefix}/${slug}/`} label={title} className="post-link"><Arrow className="arrow" />READ MORE</GatsbyLink>}
          </div>
        </div>
      </div>
    )
  }
}
