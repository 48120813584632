import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import './PostListNavigation.scss'

export const PostListNavigation = (props) => {
    const { pathPrefix, categoryFilter } = props;

    const { nodes: posts } = categoryFilter

    let categoriesArray = []
    if (posts) {
      posts.forEach(post => {
        if (!post.categories) return null;
        return post.categories.forEach( category => {
          const thisCompare = categoriesArray.find(compare => compare.wordpress_id === category.wordpress_id);
          if (!thisCompare && category.slug !== 'uncategorized') categoriesArray.push(category);
        })
      })
    }

    return (
      <div className="post-list-navigation">
        <div className="inner">
          {/* <strong>Filter by: </strong> */}
          <ul>
            <li><GatsbyLink to={`/${pathPrefix}/`} activeClassName="active">All Sectors</GatsbyLink></li>
            {/* {categoriesArray && categoriesArray.map((category , index) => category.slug !== 'uncategorised' && (
              <li key={index}><GatsbyLink to={`/${pathPrefix}/category/${category.slug}/`} activeClassName="active" dangerouslySetInnerHTML={{ __html: category.name }}/></li>
            ))} */}
          </ul>
        </div>
      </div>
    )
}
